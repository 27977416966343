import React, { Children } from 'react';

import { PutinFooter } from '../components/PutinFooter';

import { ContentCard } from '../components/ContentCard';
import MyLittlePutin from '../components/MyLittlePutin';

import DownloadIcon from '../icons/download.svg'
import NewIcon from '../icons/sparkles.svg'
import EditIcon from '../icons/edit.svg'

import logo from "../../static/assets/mlp logo.svg"

import { getEmojiFlag } from 'countries-list';
import { BasePage } from '../components/BasePage';

import { DATA_PUTIN_URI, ID_PUTIN_URI, LOAD_LOCAL_PUTIN, unidecode, uniencode, UPLOAD_PUTIN } from '../components/SerializablePutin';

import { LocalPutinsLazy } from '../components/LocalPutinsLazy';
import { LocalNamedPutin, NamedPutin } from '../components/NamedPutin';


// tslint:disable-next-line:no-var-requires
require("./index.less");

interface SharePageState extends React.HTMLProps<HTMLDivElement> {
  loadingMLP: boolean,
  error: boolean,
  copiedLinkToClip: string,
  copiedDataToClip: string,
  putinInfo?: NamedPutin,
  local?: LocalNamedPutin,
  uuid?: string,
  force: boolean
}


// https://www.gatsbyjs.com/docs/using-client-side-only-packages/
const MyLittlePutinLazy = React.lazy(() =>
  import('../components/MyLittlePutin')
)

const WeaponsBarLazy = React.lazy(() =>
    import("../components/WeaponsBar")
)

function fallbackCopyTextToClipboard(text, success:(good: boolean)=>void, err:(e)=>void) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    success(successful != null);
  } catch (e) {
      if(err) err(e);
  }

  document.body.removeChild(textArea);
}


function copyTextToClipboard(text, success:(good: boolean)=>void, err:(e)=>void = null) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, success, err);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
      success(true);
  }, function(e) {
      if(err) err(e);
  });
}

function getIDFromUrl() {
  let result: string

  if (typeof window !== 'undefined') {
    result = window.location.search.substring(1);
  }
  
  return result;
}

function getJsonFromUrl() {
  let result = null as NamedPutin

  if (typeof window !== 'undefined') {
      const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
      });
      result = unidecode(params.data);
  }

  return result ? result : null;
}

// FIXME: THIS CODE IS ASS AND HACKY AND RUSHED BUT WHATEVER 
export class SharePage extends React.Component<{}, SharePageState> {

    constructor(props) {
        super(props);
        
        this._putin = React.createRef<MyLittlePutin>(); 

        const json = getJsonFromUrl();
        const local: LocalNamedPutin | undefined = json ? LOAD_LOCAL_PUTIN(json) : undefined
        if(local?.id) {
          window.history.replaceState(null, "My Little Putin", ID_PUTIN_URI(local.id));
        }
        this.state = {force: false, local, putinInfo: local?.putin, uuid: local ? local.id : getIDFromUrl(), loadingMLP: true, error: false, copiedLinkToClip: null, copiedDataToClip: null};
    }

    forceRender() {
      this.setState({ force: !this.state.force })
    }
    _putin: React.RefObject<MyLittlePutin>;

    put() {
        return this._putin?.current;
    }

    tryToLoad() {
        if(!this.isLoading) {
            if(this.state.error) {
                this.put()?.changeMode("errorOrLoading")
            }
        }
    }

    get isLoading() {
      return this.state.loadingMLP;
    }

    get isError() {
      return this.state.error;
    }

    goHome() {
      if(typeof window === 'undefined') return;
      window.open("/", "_self")
    }

  // goTweet() {
  //   if(typeof window === 'undefined') return;
  //   window.open("/" + (edit != null ? "?edit=" + edit : "") , "_self")
  // }

    copyToClip(url: string, successFn, failFn) {

        copyTextToClipboard(url, (success)=> {
            if(success) {
              successFn()
            } else {
              failFn()
            }

            setTimeout(()=>{
                this.setState({copiedDataToClip: null, copiedLinkToClip: null})
            }, 5000);
        },
        (err)=> {
            failFn()

            setTimeout(()=>{
                this.setState({copiedDataToClip: null, copiedLinkToClip: null})
            }, 5000);
        });
    }
    
    mlp;

    private openImageInNewTab() {
      this.put()?.openInNewTab();
  }

  async generateShortUrl() {
    if(this.state.putinInfo) {
      this.setState({uuid: (await UPLOAD_PUTIN(this.state.putinInfo)).id})
    }
  }

    public render() {

        const isSSR = typeof window === "undefined"

        this.tryToLoad();


        this.mlp = this.mlp ?? <MyLittlePutinLazy resetText={"RESET"} ref={this._putin} key="mlp"  skipSplash={true} initialMode="slap" resetToData={true}
          fetchInternet={
            {uuid: this.state.uuid, done:(lnp: LocalNamedPutin, e)=>{
              this.setState({putinInfo: lnp.putin})
              if(e) this.setState({error: true})
            }}
          } 

          canRandomize={false}
          json={this.state.putinInfo} 
          canEdit={true}
          onLoad={()=>{
            this.setState({loadingMLP: false});
          }} />;

        const date = this.state.putinInfo?.created ? new Date(this.state.putinInfo?.created) : null
        const nameString = this.state.putinInfo?.name // (this.state.putinInfo?.name ? this.state.putinInfo?.name : "Unnamed Little Putin")

        const url =  () => { return this.state.uuid == null || isSSR ? null : window.location.host + window.location.pathname + "?" + this.state.uuid }
        const data_url = isSSR || !this.state.putinInfo ? null : window.location.host + DATA_PUTIN_URI(this.state.putinInfo)


        const tweet = isSSR ? null : <button 
          key="TWEET" 
          className="twitter mlp-button"
          onClick={async ()=>{
            if(!this.state.uuid) {
              await this.generateShortUrl()
            }
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url() ?? data_url)}&hashtags=MyLittlePutin,SlapPutin&text=${nameString}`, "_self")}
          }
          data-size="large"> Tweet 
        </button>

        const hWeapons = <WeaponsBarLazy 
          onClick={(w)=>{ 
            if(this.put()?.weapon == w) {
                this.put()?.setNeedsSlapMessage();
            } else {
                this.put()?.setWeapon(w); 
                this.forceRender(); 
            }
          }} 
          weapon={this.put()?.weapon} 
          exclude={["randomize", "randomize_face", "randomize_scene"]} 
          nsfw={this.put()?.putinLike}
        />


          const generate = !url() ? 
            <div key="buttons outer" style={{padding: "10px 0px 0px 0px"}} > 
              <button key="gen short link" className={"pink mlp-button"} onClick={
                this.generateShortUrl.bind(this)
              }>  Generate Short Link </button>
            </div> 
            : {type: "Other", content: <button key="copy button" style={{marginTop: "10px",  width:"100%", borderRadius:"8px", overflow: 'hidden'}} className={"pink mlp-button"} onClick={() => {
                this.copyToClip(
                  url(), 
                  ()=> {
                    this.setState({copiedLinkToClip: "COPIED LINK!"})
                  },
                  ()=> {
                    this.setState({copiedLinkToClip: "Error copying link :("})
                  }, 
                );
                }}> 
                {/* <b style={{  left:"10px", padding:"0px", position: "absolute"}} >COPY: </b> */}
                  <ContentCard className='pink white-text' key="link box" style={{padding: "0px", textAlign:"left", overflow:"hidden", whiteSpace:"nowrap", textOverflow: "ellipsis"}} children={[
                    {type: "Text", style:{fontWeight:"bold", color:"white"}, content: "Short Link"},
                    {type: "Note", style:{textAlign:"center", color:"#FFFFFFAA"}, content: this.state.copiedLinkToClip ? this.state.copiedLinkToClip : url()},
                  ]} />
              </button>}

          // <CopyIcon key="copy icon" className={"grayFill"} width="25px" height="25px" style={{top: "50%", transform: "translateY(-50%)", left:"5px", padding:"5px", position: "absolute"}} />
        const shareString = typeof window !== 'undefined' ? window.location.href.replace(/^https?:\/\//, "") : "";
        const homeAndCustom = [
          <div key="buttons outer dl" style={{padding: "5px 0px 0px 0px"}} > 
            <div key="buttons" className="mlp-control-padded" style={{gap: "5px"}}>
                <button key="download button" onClick={()=>{this.put()?.modeDownload();}}  className={"blue mlp-button"}> <DownloadIcon key="download icon" height="15px" width="15px" /> Download Image </button>
            </div>
          </div>,
          <div key="buttons outer tweet" style={{padding: "5px 0px 0px 0px"}} > 
            <div key="buttons" className="mlp-control-padded" style={{gap: "5px"}}>
              {tweet} 
            </div>
          </div>,
          // <ContentCard key="link box" border={true}  children={[
          //   {type: "Heading3", content: "Share Links"},
            !data_url ? null : {type: "Other", content: <button style={{marginTop: "10px", width:"100%", borderRadius:"8px", overflow: 'hidden'}} className={"gray mlp-button"} onClick={()=>{
              this.copyToClip(
                data_url, 
                ()=> {
                  this.setState({copiedDataToClip: "COPIED LINK!"})
                },
                ()=> {
                  this.setState({copiedDataToClip: "Error copying link :("})
                }, 
              );
            }} children={[
              <ContentCard className='gray' key="long link box" style={{padding: "0px", textAlign:"left", overflow:"hidden", whiteSpace:"nowrap", textOverflow: "ellipsis"}} children={[
                {type: "Text", style:{fontWeight:"bold"}, content: "Long Link"},
                {type: "Note", style:{textAlign:"center"}, content: this.state.copiedDataToClip ? this.state.copiedDataToClip : data_url},
              ]} />
            ]} /> },
            generate
          // ]} />,

        ]

        const edit = <button key="edit button" style={{marginTop:"10px", height: "50px"}} onClick={()=>{this.put()?.modeEdit(this.state.uuid ?? uniencode(this.state.putinInfo), this.state.uuid == null)}}  className={"gray mlp-button"}> <EditIcon key="edit icon" className="whiteFill" height="16px" width="16px" /> Edit Putin </button>


        const nameCard =  <ContentCard key="conetn" style={{padding: "3px"}} border={false}  children={[   
          ...(this.isLoading ? [{type: "Heading1", content:"Loading..."}] : [
              this.state.error ? {type: "Heading1", content:  "Error"} : 
              nameString ? {type: "Heading1", content: nameString} : null,
              this.state.error ? {type: "Note", content: "Error loading tiny little Putin 😔"} : null,
              <div children={[
                <ContentCard key="conetawn" className='country-date' border={false}  children={[
                  !this.state.error ? {type: "Other", content: <p key="location" style={{padding:"0px", marginTop: "-5px"}} className={"grayText"}> Little Putin from {this.state.putinInfo?.country ? getEmojiFlag(this.state.putinInfo?.country) : "🏳️‍🌈"} </p>} : null,
                  !this.state.error ? {type: "Other", content: <p key="created" style={{fontSize:"12px", padding:"0px",}} className={"grayText"}> Created {date?.toLocaleString().split(',')[0] ?? "???"} </p>} : null,
                ]} />
              ]} />
              
          ]),   
        ]} />



        const leftSide = [

            <div key="top bar" className="mlp-control-padded" style={{height:"40px", justifyContent:"left"}}>
              <button key="home button" style={{background: "transparent", border: "none" , width: "40px", height: "40px", flex: "1"}} onClick={()=>{this.goHome()}}> <img style={{height: "100%"}} src={logo} /></button>
              <button key="create new button" onClick={()=>{this.goHome()}} style={{maxWidth:"200px", fontWeight: "bold"}} className={"rainbow mlp-button"}> <NewIcon className="whiteFillAlways" height="18px" width="18px" /> Create New Putin </button>
            </div>,

            nameCard,
            !isSSR && <React.Suspense  key="sus" fallback={<div  key="square" className={"square"}/>}>
                {this.mlp}
            </React.Suspense>,
            <div style={{marginTop: "5px"}} children={ !isSSR ? [
                <React.Suspense key="Weapons Bar Lazy" fallback={<div key="Loading Wep" className={""} />}>
                  {hWeapons}
                </React.Suspense>,
            ] : []} />,


        ]

        const rightSide = [
          // edit,
            <ContentCard key="conetn" style={{}} border={false}  children={[
              {type: "Heading3", style: {fontWeight: "normal"}, content: <p key="slap" style={{color: "orange", textAlign: "center"}}> Click or tap to <b>SLAP PUTIN</b>! </p>},  
            ]} />,
          <ContentCard key="info box" style={{textAlign:"left"}} border={true}  children={[
            {type: "Heading3", content: "SHARE"},
            ...(this.isLoading || this.isError ? [{type: "Note", content:"Loading..."}] : homeAndCustom),
          ]} />,

          <ContentCard key="local" border={true} children={[
            { type: "Heading1", content:"Recent Putins" },
            <LocalPutinsLazy expanded={true} show={10} size={{x: 150, y: 150}} /> ,
            { type: "Heading3", content: <a style={{textAlign:"center", display:"block", width: "100%", paddingTop: "10px"}} href="/gallery"> View Gallery </a> },
          ]} />,
          <PutinFooter  key="footer" goHome={true} />
        ]

        return <BasePage key="base" title={this.state.putinInfo?.name ?? undefined} className="white" >
        <div key="SPLIT" className="SplitPage" >
            <div key="Left" className="PageContent " style={{paddingBottom: "0px"}} children={[
                ...leftSide
            ]} />
            <div key="Right" className="PageContent" style={{paddingTop: "0px"}}  children={[
                ...rightSide
            ]} />
        </div>
        </BasePage>
    }
}

export default SharePage;

